import { gql } from "@apollo/client";

export const GET_PENDING_POSTS = gql`
  query GetPendingPosts($size: Int!, $offset: Int!) {
    posts(
      where: { ai_checked: { _is_null: true } }
      offset: $offset
      limit: $size
    ) {
      id
      connector_id
      title
      description
      content
      topic
      tags
      language
    }
  }
`;

export const GET_LIVE_POSTS = gql`
  query GetLivePosts($size: Int!, $offset: Int!) {
    posts(
      where: { ai_checked: { _is_null: false } }
      offset: $offset
      limit: $size
    ) {
      id
      connector_id
      title
      description
      source_link
      content
      topic
      tags
      language
       gif {
        url
        id
      }
    }
  }
`;

export const UPDATE_POSTS = gql`
  mutation UpdatePosts($id: String!, $input: posts_set_input!) {
    update_posts(where: { id: { _eq: $id } }, _set: $input) {
      affected_rows
      returning {
        id
        source_link
        title
        description
        content
      }
    }
  }
`;
