import {
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CancelButton, MainButton, TextButton } from "../components/buttons";
import { FormGrid, GridItem4, GridItem1 } from "../components/grids";
import { useAppDispatch, useAppSelector } from "../store";
import { setLastError, setView, Views } from "../store/session";
import { Language } from "../model/languages";
import { suggestTopic } from "../model/posts";
import { LanguageMenu } from "../components/LanguageMenu";
import { getGif, Gif, GifSuggestion, insertGif, suggestBestGif } from "../model/gifs";
import { GiphyEmbed } from "../components/GiphyEmbed";
import { useMutation } from "@apollo/client";
import { UPDATE_POSTS } from "../Queries/PostQueries";

interface PostState {
  gif?: Gif;
  source: string;
  source_link: string;
  title: string;
  description: string;
  content: string;
  topic: string;
  tags: string;
  language: Language;
  keywordsSuggestion?: string;
  bestGifSuggestion?: GifSuggestion;
  loadingKeywordsSuggestion: boolean;
  loadingBestGifSuggestion: boolean;
}

export const EditPost = () => {
  const user = useAppSelector((state) => state.session.user);
  const editPost = useAppSelector((state) => state.session.editPost);
  const dispatch = useAppDispatch();

  const [postState, setPostState] = useState<PostState>({
    source: editPost?.post.source || "",
    source_link: editPost?.post.source_link || "",
    title: editPost?.post.title || "",
    description: editPost?.post.description || "",
    content: editPost?.post.content || "",
    topic: editPost?.post.topic || "",
    tags: editPost?.post.tags?.join("\n") || "",
    language: editPost?.post.language || Language.EN,
    loadingKeywordsSuggestion: false,
    loadingBestGifSuggestion: false,
  });

  const isFormChanged = useMemo(() => {
    if (!editPost?.post) return false;

    return (
      postState.title !== editPost.post.title ||
      postState.description !== editPost.post.description ||
      postState.content !== editPost.post.content
    );
  }, [postState, editPost?.post]);

  const isFormValid = useMemo(() => {
    return (
      postState.title.trim() !== "" &&
      postState.description.trim() !== "" &&
      postState.content.trim() !== ""
    );
  }, [postState.title, postState.description, postState.content]);

  const [updatePost] = useMutation(UPDATE_POSTS);

  const handleCancel = useCallback(() => {
    if (editPost?.back === 'live') {
      dispatch(setView(Views.LivePosts));
    } else {
      dispatch(setView(Views.PendingPosts));
    }
  }, [dispatch, editPost?.back]);

  const handleInputChange =
    (field: keyof PostState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPostState((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const handleSave = useCallback(async () => {
    if (!isFormChanged) {
      return;
    }

    if (user && editPost) {
      dispatch(setLastError());
      try {
        var gifId = editPost.post.gifId;
        if (postState.bestGifSuggestion) {
          const { data, error } = await insertGif(user, {
            provider: postState.bestGifSuggestion.provider,
            providerId: postState.bestGifSuggestion.providerId,
            embedUrl: postState.bestGifSuggestion.embedUrl,
          });
          if (error) throw error;
          gifId = data;
        }

        const input = {
          id: gifId,
          title: postState.title,
          description: postState.description,
          content: postState.content || undefined,
          topic: postState.topic,
          tags: postState.tags.split("\n").map((s) => s.trim()),
          language: postState.language,
        };

        const { data } = await updatePost({
          variables: { id: editPost.post.id, input },
        });

        handleCancel();
      } catch (err) {
        dispatch(setLastError(String(err)));
      }
    }
  }, [
    user,
    editPost,
    postState,
    dispatch,
    updatePost,
    handleCancel,
    isFormChanged,
  ]);

  const handleSuggestKeywords = useCallback(async () => {
    if (user && postState.title && postState.description) {
      dispatch(setLastError());
      try {
        setPostState((prev) => ({
          ...prev,
          loadingKeywordsSuggestion: true,
          keywordsSuggestion: undefined,
        }));

        const { data } = await suggestTopic(
          user,
          postState.title,
          postState.description,
          postState.content
        );

        setPostState((prev) => ({
          ...prev,
          loadingKeywordsSuggestion: false,
          keywordsSuggestion: data,
        }));
      } catch (err) {
        dispatch(setLastError(String(err)));
        setPostState((prev) => ({
          ...prev,
          loadingKeywordsSuggestion: false,
        }));
      }
    }
  }, [
    postState.content,
    postState.description,
    dispatch,
    user,
    postState.title,
  ]);

  const handleSuggestBestGif = useCallback(async () => {
    if (
      user &&
      postState.keywordsSuggestion &&
      postState.title &&
      postState.description
    ) {
      dispatch(setLastError());
      try {
        setPostState((prev) => ({
          ...prev,
          loadingBestGifSuggestion: true,
          bestGifSuggestion: undefined,
        }));

        const { data } = await suggestBestGif(
          user,
          postState.keywordsSuggestion,
          postState.title,
          postState.description,
          Language.EN
        );

        setPostState((prev) => ({
          ...prev,
          loadingBestGifSuggestion: false,
          bestGifSuggestion: data,
        }));
      } catch (err) {
        dispatch(setLastError(String(err)));
        setPostState((prev) => ({
          ...prev,
          loadingBestGifSuggestion: false,
        }));
      }
    }
  }, [
    postState.description,
    dispatch,
    postState.keywordsSuggestion,
    user,
    postState.title,
  ]);

  const loadGif = useCallback(async () => {
    if (user && editPost?.post.gifId) {
      dispatch(setLastError());
      try {
        const { data, error } = await getGif(user, editPost?.post.gifId);
        if (error) throw error;
        setPostState((prev) => ({
          ...prev,
          gif: data,
        }));
      } catch (err) {
        dispatch(setLastError(String(err)));
      }
    }
  }, [dispatch, editPost?.post.gifId, user]);

        useEffect(()=>{
          loadGif();
        },[loadGif])

  let editVerb = editPost?.post.id
    ? `Edit post ${editPost?.post.id}`
    : "New post";

  return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb}</Typography>
            </Stack>
          </Stack>
          <br />
          <FormGrid>
            <GridItem4>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  type="text"
                  value={postState.title}
                  onChange={handleInputChange("title")}
                />
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  type="text"
                  value={postState.description}
                  onChange={handleInputChange("description")}
                />
              <TextField
                fullWidth
                multiline
                rows={10}
                id="content"
                label="Content"
                type="text"
                value={postState.content}
                onChange={handleInputChange("content")}
              />
              <TextField
                  fullWidth
                  id="source"
                  label="Source"
                  type="text"
                  value={postState.source_link}
                  onChange={handleInputChange("source")}
                  disabled
                />
                <Typography variant="body2">
                  <Link href={postState.source} target="_blank">
                    {postState.source}
                  </Link>
                </Typography>
                <TextField
                fullWidth
                id="tags"
                label="Tags"
                multiline
                type="text"
                value={postState.tags}
                onChange={handleInputChange("tags")}
              />
              </Stack>
            </GridItem4>
            <GridItem4>
              {postState.gif && (
                <GiphyEmbed embedUrl={postState.gif.embedUrl} />
              )}
            </GridItem4>
            </FormGrid>
            <FormGrid>
            <GridItem1>
            <LanguageMenu
                fullWidth
                language={postState.language}
                onChange={(lang) =>
                  setPostState((prev) => ({ ...prev, language: lang }))
                }
              />
              </GridItem1>
              </FormGrid>
          <FormGrid>
            <GridItem4>
              <Stack>
                <TextButton
                  disabled={postState.loadingKeywordsSuggestion}
                  onClick={handleSuggestKeywords}
                >
                  Suggest keywords (OpenAI / ChatGPT)
                </TextButton>
                {postState.loadingKeywordsSuggestion && <LinearProgress />}
                {postState.keywordsSuggestion && (
                  <TextField
                    label="Keywords suggestion"
                    fullWidth
                    multiline
                    disabled
                    value={postState.keywordsSuggestion}
                  />
                )}
              </Stack>
            </GridItem4>
            <GridItem4>
              <Stack>
                <TextButton
                  disabled={postState.loadingBestGifSuggestion}
                  onClick={handleSuggestBestGif}
                >
                  GIF lookup (
                  {postState.bestGifSuggestion?.provider || "Giphy / Tenor"} +
                  OpenAI / ChatGPT)
                </TextButton>
                {postState.loadingBestGifSuggestion && <LinearProgress />}
                {postState.bestGifSuggestion && (
                  <Stack>
                    <TextField
                      label="Used keywords"
                      fullWidth
                      multiline
                      disabled
                      value={postState.bestGifSuggestion.keywords}
                    />
                    <Link href={postState.bestGifSuggestion.embedUrl}>
                      {postState.bestGifSuggestion.embedUrl}
                    </Link>
                    <GiphyEmbed
                      embedUrl={postState.bestGifSuggestion.embedUrl}
                    />
                  </Stack>
                )}
              </Stack>
            </GridItem4>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            {isFormChanged && isFormValid && (
              <MainButton variant="contained" onClick={handleSave}>
                {editPost?.post.id ? "Save" : "Create"}
              </MainButton>
            )}
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};
